<template>
  <c-leaf>
    <form novalidate @submit="submit">
      <slot v-bind="{ $v: $v.form, controls: form, formData }"/>
    </form>
  </c-leaf>
</template>

<script>
export default {
  name: 'Form',
  props: {
    /*
    * e.g.
    * {
    *   [controlName]: {
    *     value: null | false | '' | [] | ...,
    *     validators: {
    *       [validatorName]: $root.validators.required,
    *       [validatorName2]: value => true | false
    *     },
    *     feedbacks: {
    *       success: 'Lorem ipsum',
    *       errors: {
    *         [validatorName]: 'Dolor sit amet',
    *         [validatorName2]: 'Dolor amet sit'
    *       }
    *     }
    *   }
    * }
    */
    controls: {
      type: Object,
      default: () => ({})
    },
    eventsOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: this.controls
    }
  },
  validations () {
    return {
      form: Object.keys(this.form)
        .reduce((controls, cKey) => {
          const validators = this.form[cKey].validators || {}
          const contextBindedValidators = Object.keys(validators)
            .reduce((v, vKey) => Object.assign(v, { [vKey]: typeof validators[vKey] === 'function' ? value => validators[vKey](value, this.form) : validators[vKey] }), {})

          return Object.assign(controls, { [cKey]: { value: contextBindedValidators } })
        }, {})
    }
  },
  computed: {
    formData () {
      return Object.keys(this.form)
        .reduce((controls, cKey) => Object.assign(controls, { [cKey]: this.form[cKey].value }), {})
    }
  },
  methods: {
    change () {
      if (this.eventsOnly) {
        this.$emit('form:change', { $v: this.$v.form, controls: this.form, formData: this.formData })
      }
    },
    submit (e) {
      this.$v.$touch()

      if (this.$v.$invalid) {
        e.preventDefault()
      }

      if (this.eventsOnly) {
        e.preventDefault()

        if (this.$v.$invalid) this.$emit('form:submit:invalid', { $v: this.$v.form, controls: this.form, formData: this.formData })
        else this.$emit('form:submit', { $v: this.$v.form, controls: this.form, formData: this.formData })
      }
    }
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler () {
        this.change()
      }
    }
  }
}
</script>

<style lang="scss"></style>
