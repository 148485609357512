<template>
  <checkout-form
    class="checkout-option-form"
    :controls="controls"
    eventsOnly
    @form:change="onChange"
    @form:submit="onChange"
    v-slot="formSlotProps"
  >
    <b-form-radio
      v-if="!hideOptionHeader"
      class="option-form-control"
      v-model="stepContext.optionsActive[name]"
      size="lg"
      :name="name"
      :value="value"
    >{{ text }}</b-form-radio>

    <div class="option-form-content" v-show="isActive && $scopedSlots.default">
      <slot v-bind="formSlotProps"/>
    </div>
  </checkout-form>
</template>

<script>
import CheckoutForm from '@/components/form/FormContainer'
import CheckoutStep from '@/components/authenticated/Order/CheckoutStep'

import { getNamedContext } from '@/assets/js/helper/vm'

export default {
  name: 'CheckoutOptionForm',
  components: {
    CheckoutForm
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Boolean],
      required: true
    },
    text: {
      type: String,
      required: true
    },
    hideOptionHeader: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      form: null
    }
  },
  computed: {
    isActive () {
      return this.stepContext.optionsActive[this.name] === this.value
    }
  },
  methods: {
    updateControl ({ key = '', value }) {
      if (this.form.controls[key]) {
        this.form.controls[key].value = value
      }
    },
    onChange (form = null) {
      this.form = form

      if (this.isActive) {
        this.stepContext.$emit('optionform:change', Object.assign({ option: { name: this.name, value: this.value } }, this.form))
      }
    }
  },
  beforeCreate () {
    this.stepContext = getNamedContext(CheckoutStep.name, this)
  },
  created () {
    this.$on('control:change', this.updateControl)
  },
  watch: {
    isActive (is) {
      if (is) this.onChange(this.form)
    }
  }
}
</script>

<style lang="scss">
$checkoutoptionform-control-font-size: $font-size-lg !default;
$checkoutoptionform-control-font-weight: $font-weight-bold !default;

.checkout-option-form {
  .option-form-control {
    font-size: $checkoutoptionform-control-font-size;
    font-weight: $checkoutoptionform-control-font-weight;

    .custom-control-input {
      ~ .custom-control-label {
        margin: 0;
      }
    }
  }
}
</style>
